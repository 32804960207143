@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.App {
  text-align: center;
}

main {
  padding: 2em 0;
  margin-top: 64px; /* Add margin-top to offset the fixed header */
}

section {
  margin: 1em 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
}

.course-card {
  position: relative;
  overflow: hidden;
  background-color: #666666 !important; /* Ensure high specificity */
  color: white !important; /* Ensure high specificity */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  height: 200px; /* Set a fixed height for uniformity */
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-card .course-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(1px) brightness(0.48);
  transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
  z-index: 1;
}

.course-card .course-overlay {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.course-card .course-title,
.course-card .course-description {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.course-card .course-title {
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  font-size: 24px;
}

.course-card .course-description {
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.course-card:hover .course-image {
  filter: blur(0); /* Remove blur on hover */
  opacity: 0;
}

.course-card:hover .course-title {
  opacity: 0;
}

.course-card:hover .course-description {
  opacity: 1;
}

h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
  padding-bottom: 1em;
}


textarea {
  width: 80%;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}

button {
  background-color: #666666;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #666666;
}

h3 {
  margin-top: 20px;
}

pre {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
  text-align: left;
  width: 80%;
  margin: 20px auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
}